(function (window, document) {
    "use strict";

    var jQuery, $;
    var api_address = "https://api.pinbus.com/";

    // Load jQuery if not present
    if (window.jQuery === undefined || window.jQuery.fn.jquery !== '2.1.4') {
        var script_tag = document.createElement('script');

        script_tag.setAttribute("type", "text/javascript");
        script_tag.setAttribute("src", "//ajax.googleapis.com/ajax/libs/jquery/2.1.0/jquery.min.js");
        script_tag.onload = scriptLoadHandler;
        script_tag.onreadystatechange = function () { // Same thing but for IE
            if (this.readyState == 'complete' || this.readyState == 'loaded') {
                scriptLoadHandler();
            }
        };
        // Try to find the head, otherwise default to the documentElement
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);

    } else {
        // The jQuery version on the window is the one we want to use
        $ = jQuery = window.jQuery;
        scriptLoadHandler();
    }

    // Called once jQuery has loaded
    function scriptLoadHandler() {

        // Restore $ and window.jQuery to their previous values and store the
        // new jQuery in our local jQuery variable
        $ = jQuery = window.jQuery;

        // Call our main function
        loadScript(
            "//ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js",
            loadScript("//cdn.jsdelivr.net/npm/fuse.js@6.5.3", main())
        );

    }

    function loadScript(url, callback) {
        /* Load script from url and calls callback once it's loaded */
        var scriptTag = document.createElement('script');
        scriptTag.setAttribute("type", "text/javascript");
        scriptTag.setAttribute("src", url);
        if (typeof callback !== "undefined") {
            if (scriptTag.readyState) {
                /* For old versions of IE */
                scriptTag.onreadystatechange = function () {
                    if (this.readyState === 'complete' || this.readyState === 'loaded') {
                        callback();
                    }
                };
            }
        }
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(scriptTag);
    }

    function main() {
        /******* Load CSS *******/
        var css_link = $("<link>", {
            rel: "stylesheet",
            type: "text/css",
            href: "//fonts.googleapis.com/css?family=Fira+Sans:400,300,700"
        });
        css_link.appendTo('head');

        var css_link = $("<link>", {
            rel: "stylesheet",
            type: "text/css",
            href: "//ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/themes/smoothness/jquery-ui.min.css"
        });
        css_link.appendTo('head');

        var css_link = $("<link>", {
            rel: "stylesheet",
            type: "text/css",
            href: "//buscador.pinbus.com/css/widget.css"
        });

        css_link.appendTo('head');

        $(document).ready(function () {

            $("#pinbus-search-form").html('<span style="display:block; padding:1rem 1.5rem;">Cargando...</span>');

            var site_url = 'https://tiquetes.pinbus.com';
            var desarrollado_pinbus = 'https://cdn.pinbus.com/common/img/widget/desarrollado_por_pinbus_claro.svg';
            var isMobile = false;
            var logoDesarrolladoPinbus = false;

            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                isMobile = true;
                site_url = 'https://pinbus.com';
                desarrollado_pinbus = 'https://cdn.pinbus.com/common/img/widget/desarrollado_por_pinbus_oscuro.svg';
            }

            // capture user from widget
            var user = atob($("#pinbus-search-form").data('pb'));
            var pass = atob($("#pinbus-search-form").data('key'));

            // po = page orientation [portrait|landscape]
            var po = ($("#pinbus-search-form").attr('data-po')) ? $("#pinbus-search-form").data('po') : '';
            var tab = ($("#pinbus-search-form").attr('data-po')) ? 6 : 5;
            var params = {
                'email': user,
                'password': pass
            };

            // Get Token from Auth
            if (params.email.indexOf('brasilia') !== -1)
                site_url = 'https://test_brasilia.pinbus.com';

            if (params.email.indexOf('gomezhernandez') !== -1)
                site_url = 'https://gomez_hernandez.pinbus.com';

            if (params.email.indexOf('wlpalmira') !== -1)
                site_url = isMobile ? 'https://mexpresopalmira.pinbus.com' : 'https://expresopalmira.pinbus.com';

            if (params.email.indexOf('wllaterminal') !== -1) {
                site_url = 'https://laterminal.pinbus.com';
                logoDesarrolladoPinbus = true;
            }

            if (params.email.indexOf('wlmedellin') !== -1) {
                site_url = isMobile ? 'https://mterminaldemedellin.pinbus.com' : 'https://terminalmedellin.pinbus.com';
                logoDesarrolladoPinbus = true;
            }

            if (params.email.indexOf('cotranal') !== -1) {
                site_url = isMobile ? 'https://mcotranal.pinbus.com' : 'https://cotranal.pinbus.com';
            }

            if (params.email.indexOf('taxcentral') !== -1) {
                site_url = isMobile ? 'https://mtaxcentral.pinbus.com' : 'https://taxcentral.pinbus.com';
            }

            if (params.email.indexOf('cotrans') !== -1) {
                site_url = isMobile ? 'https://mcotrans.pinbus.com' : 'https://cotrans.pinbus.com';
            }

            if (params.email.indexOf('cootrayal') !== -1) {
                site_url = isMobile ? 'https://mcootrayal.pinbus.com' : 'https://cootrayal.pinbus.com';
            }

            if (params.email.indexOf('horariobuses') !== -1) {
                site_url = isMobile ? 'https://mhorariodebuses.pinbus.com' : 'https://horariobuses.pinbus.com';
                logoDesarrolladoPinbus = true;
                desarrollado_pinbus = 'https://cdn.pinbus.com/common/img/widget/desarrollado_por_pinbus_oscuro.svg';
            }

            if (params.email.indexOf('morichal') !== -1) {
                site_url = isMobile ? 'https://mmorichal.pinbus.com' : 'https://morichal.pinbus.com';
            }

            if (params.email.indexOf('expresodelsol') !== -1) {
                site_url = isMobile ? 'https://mexpresodelsol.pinbus.com' : 'https://expresodelsol.pinbus.com';
            }

            if (params.email.indexOf('transpurificacion') !== -1) {
                site_url = isMobile ? 'https://mtranspurificacion.pinbus.com' : 'https://transpurificacion.pinbus.com';
            }

            if (params.email.indexOf('cootransfusa') !== -1) {
                site_url = isMobile ? 'https://mcootransfusa.pinbus.com' : 'https://cootransfusa.pinbus.com';
            }

            if (params.email.indexOf('cootranshuila') !== -1) {
                site_url = isMobile ? 'https://mcootranshuila.pinbus.com' : 'https://cootranshuila.pinbus.com';
            }

            if (params.email.indexOf('arimena') !== -1)
                site_url = isMobile ? 'https://marimena.pinbus.com' : 'https://arimena.pinbus.com';

            if (params.email.indexOf('wlbarranquilla') !== -1) {
                site_url = isMobile ? 'https://mterminaldebarranquilla.pinbus.com' : 'https://terminalbarranquilla.pinbus.com';
                logoDesarrolladoPinbus = true;
            }

            if (params.email.indexOf('wlarmenia') !== -1) {
                site_url = isMobile ? 'https://mterminaldearmenia.pinbus.com' : 'https://terminalarmenia.pinbus.com';
                logoDesarrolladoPinbus = true;
            }

            if (params.email.indexOf('wlibague') !== -1) {
                site_url = isMobile ? 'https://mterminaldeibague.pinbus.com' : 'https://terminalibague.pinbus.com';
                logoDesarrolladoPinbus = true;
                desarrollado_pinbus = 'https://cdn.pinbus.com/common/img/widget/desarrollado_por_pinbus_oscuro.svg';
            }

            if (params.email.indexOf('wlmelgar') !== -1) {
                site_url = isMobile ? 'https://mterminaldemelgar.pinbus.com' : 'https://terminalmelgar.pinbus.com';
                logoDesarrolladoPinbus = true;
            }

            if (params.email.indexOf('wlhonda') !== -1) {
                site_url = isMobile ? 'https://mterminaldehonda.pinbus.com' : 'https://terminalhonda.pinbus.com';
                logoDesarrolladoPinbus = true;
            }

            if (params.email.indexOf('wlgirardot') !== -1) {
                site_url = isMobile ? 'https://mterminaldegirardot.pinbus.com' : 'https://terminalgirardot.pinbus.com';
                logoDesarrolladoPinbus = true;
                desarrollado_pinbus = 'https://cdn.pinbus.com/common/img/widget/desarrollado_por_pinbus_oscuro.svg';
            }

            if (params.email.indexOf('wlarauca') !== -1)
                site_url = isMobile ? 'https://marauca.pinbus.com' : 'https://arauca.pinbus.co';

            if (params.email.indexOf('wlfoccidental') !== -1)
                site_url = 'https://test_foccidental.pinbus.com';

            if (params.email.indexOf('wlmacarena') !== -1)
                site_url = isMobile ? 'https://mmacarena.pinbus.com' : 'https://tiquetes.flotalamacarena.com/';

            if (params.email.indexOf('coomotor') !== -1)
                site_url = isMobile ? 'https://mcoomotor.pinbus.com' : 'https://tiquetes.coomotor.com.co';

            if (params.email.indexOf('copetran') !== -1)
                site_url = isMobile ? 'https://mcopetran.pinbus.com' : 'https://tiquetes.copetran.com';

            if (params.email.indexOf('cointrasur') !== -1) {
                site_url = isMobile ? 'https://mcointrasur.pinbus.com' : 'https://cointrasur.pinbus.com';
                logoDesarrolladoPinbus = true;
                desarrollado_pinbus = 'https://cdn.pinbus.com/common/img/widget/desarrollado_por_pinbus_oscuro.svg';
            }

            if (params.email.indexOf('rapidotolima') !== -1)
                site_url = isMobile ? 'https://mrapidotolima.pinbus.com' : 'https://rapidotolima.pinbus.com';

            // console.log({params, site_url, logoDesarrolladoPinbus});


            var origin_id, destination_id;

            var search_form = $(`<div id="pinbus-widget-search desktop">
                                    <div id="pb_sf_search_module">
                                        <h2>Compra tu <strong>Pasaje de Bus</strong></h2>
                                        <form id="pb_sf_search_form" autocomplete="off">
                                            <input type="hidden" id="pb_sf_pasajeros" value="1" />
                                            <div class="row">
                                                <div class="small-12 medium-10 columns">
                                                    <div class="row">
                                                        <div id="div_container_ida" class="small-12 medium-3 columns">
                                                            <label for="origin" class="control-label">Origen</label>
                                                        </div>
                                                        <div id="div_container_destino" class="small-12 medium-3 columns">
                                                            <label for="destino" class="control-label">Destino</label>
                                                        </div>
                                                        <div id="div_container_salida" class="small-6 medium-3 columns">
                                                            <label for="pb_sf_departure_submit" class="control-label">Fecha de Salida</label>
                                                            <input type="hidden" id="pb_sf_departure_submit" value="" readonly="readonly">
                                                        </div>
                                                        <div id="div_container_retorno" class="small-6 medium-3 columns">
                                                            <label for="origin" class="control-label">Fecha de Regreso</label>
                                                            <input type="hidden" id="pb_sf_return_submit" value="" readonly="readonly">
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="" class="control-label">&nbsp;</label>
                                                <div id="button_container" class="small-12 medium-2 columns"></div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="pinbus-desarrollado_pinbus"></div>
                                </div>`);

            if (po == 'portrait')
                search_form = $('<div id="pb_sf_search_module"><h2>Compra tu <strong>Pasaje de Bus</strong></h2><form id="pb_sf_search_form"><div class="row"> <div id="div_container_ida" class="small-12 columns"></div> </div><div class="row"> <div id="div_container_destino" class="small-12 columns"></div> </div><div class="row"> <div id="div_container_salida" class="small-12 columns medium-6">  <input type="hidden" id="pb_sf_departure_submit" value=""> </div><div id="div_container_retorno" class="small-12 medium-6 columns"> <input type="hidden" id="pb_sf_return_submit" value=""> </div></div><div class="row"> <div class="small-12 medium-6 columns"><input type="hidden" id="pb_sf_pasajeros" value="1" /><a href="https://pinbus.com" target="_blank"><img src="//test_buscador.pinbus.com/img/operadoporpinbus.png" class="img-responsive" alt="" /></a></div><div id="button_container" class="small-12 medium-6 columns"></div></div></form></div><div id="pb_sf_datepicker_wrapper"/>');

            if (isMobile) {

                search_form = $(`<div id="pinbus-widget-search mobile">
                                    <div id="pb_sf_search_module">
                                        <h2>Compra tu <strong>Pasaje de Bus</strong></h2>
                                        <form id="pb_sf_search_form" autocomplete="off">
                                            <input type="hidden" id="pb_sf_pasajeros" value="1" />
                                            <div class="row">
                                                <div class="small-12 columns">
                                                    <label class="control-label">Ciudad de Origen</label>
                                                    <input type="text" id="pb_sf_f_origin" placeholder="Ingresa la ciudad de donde sales" class="searchInput">
                                                    <div class="city-chooser-popup" style="display:none">
                                                        <div class="city-chooser-header">
                                                            Seleccione el origen
                                                            <i class="fa fa-times pull-right"></i>
                                                        </div>
                                                        <div id="div_container_ida" class="city-chooser-content"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="small-12 columns">
                                                    <label class="control-label">Ciudad de Destino</label>
                                                    <input type="text" id="pb_sf_f_destino" placeholder="Ingresa la ciudad a donde viajas" class="searchInput" disabled="true">
                                                    <div class="city-chooser-popup" style="display:none">
                                                        <div class="city-chooser-header">
                                                            Seleccione el Destino
                                                            <i class="fa fa-times pull-right"></i>
                                                        </div>
                                                        <div id="div_container_destino" class="city-chooser-content"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="small-12 columns">
                                                    <label class="control-label">Fechas de Viaje</label>
                                                </div>
                                                <div id="div_container_salida" class="small-6 columns">
                                                    <input type="hidden" id="pb_sf_departure_submit" value="" readonly="readonly">
                                                </div>
                                                <div id="div_container_retorno" class="small-6 columns">
                                                    <input type="hidden" id="pb_sf_return_submit" value="" readonly="readonly">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div id="button_container" class="small-12 columns"></div>
                                            </div>
                                        </form>
                                        <div id="pinbus-desarrollado_pinbus"></div>
                                    </div>
                                </div>`);
            }

            var origin_placeholder = (isMobile) ? 'Ingresa la ciudad de donde sales' : 'Seleccione Origen';

            var origin = $('<input id="pb_sf_origin" name="origin" type="text" class="searchInput required" placeholder="' + origin_placeholder + '" tabindex="1" autocomplete="nope" />');
            var destination = $('<input id="pb_sf_destiny" name="destiny" type="text" class="searchInput required" placeholder="Seleccione Destino" tabindex="2" readonly="true" autocomplete="nope" />');

            var departure_date = $('<input id="pb_sf_departure" placeholder="Fecha Salida" name="pb_sf_departure" class="required" type="text" tabindex="3" readonly="readonly" autocomplete="off"/>');
            var return_date = $('<input id="pb_sf_return" placeholder="Regreso (opc.)" name="pb_sf_return" type="text" tabindex="4" readonly="readonly" autocomplete="off"/>');

            var button = $('<button id="pb_sf_button_submit" class="btn_search radius expand tiny right gtm-buscar" type="submit" tabindex="' + tab + '" disabled>Buscar</button>');

            // DatePicker for Departure
            $(departure_date).datepicker({
                minDate: new Date(),
                defaultDate: new Date(),
                altField: "#pb_sf_departure_submit",
                altFormat: "yy-mm-dd",
                beforeShow: function (input, inst) {
                    $(departure_date).datepicker('widget').addClass('pb_sf_datepicker').wrap('<div class="pb_sf_datepicker_wrapper"/>');
                },
                onSelect: function (date) {
                    validate_form();
                },
                onClose: function (selectedDate) {
                    validate_form();
                    $(return_date).datepicker("option", "minDate", selectedDate);
                }
            });

            // DatePicker for Return
            $(return_date).datepicker({
                minDate: new Date(),
                altField: "#pb_sf_return_submit",
                altFormat: "yy-mm-dd",
                beforeShow: function (input, inst) {
                    $(return_date).datepicker('widget').wrap('<div class="pb_sf_datepicker_wrapper"/>')
                    $(return_date).datepicker('widget').addClass('pb_sf_datepicker');
                }
            });

            // Extra setting
            $(departure_date, return_date).datepicker('widget').wrap('<div id="pb_sf_datepicker_wrapper"/>');

            $(departure_date, return_date).keypress(function (e) { return false });
            $(departure_date, return_date).keydown(function (e) { return false });


            // Login to the API
            $.ajax({
                url: api_address + "auth/login",
                method: "POST",
                data: params
            })
                // After getting the token load all the components
                .done(function (response) {

                    var token = response.content.token;

                    // Get Stations
                    $.ajax({
                        url: api_address + "get_origin_stations",
                        method: "GET",
                        headers: {
                            "Authorization": token
                        }
                    })
                        .done(function (response) {

                            var cities = parseResults(response.content);

                            // Fuse Options
                            var fuseOptions = {
                                includeScore: true,
                                includeMatches: true,
                                keys: [
                                    'label'
                                ]
                            };

                            var fuseCities = new Fuse(cities, fuseOptions);

                            var destinations = [];

                            // Options for Origins Autocomplete
                            var options = {
                                open: function () {
                                    $(this)
                                        .autocomplete('widget')
                                        .addClass('pb_autocomplete')
                                        .wrap('<div class="pb_autocomplete_wrapper"></div>');
                                },
                                minLength: 0,
                                autoFocus: true,
                                //Replace source for Fuse search 
                                //source: cities,
                                source: function (request, response) {
                                    var citiesList = [];
                                    var result = fuseCities.search(request.term);
                                    result = result.sort((a, b) => a.score - b.score);
                                    result.forEach(item => {
                                        if (item.score < 0.5)
                                            citiesList.push(item.item);
                                        else if (item.score < 0.4)
                                            citiesList.push(item.item);
                                    })
                                    result = citiesList;
                                    response(result);
                                },
                                focus: function (event, ui) {
                                    ;
                                    return false;
                                },
                                select: function (event, ui) {

                                    if (isMobile) {
                                        $('#div_container_ida').parent().hide();
                                        $('#pb_sf_f_origin').val(ui.item.label);
                                    }

                                    origin_id = ui.item.value;
                                    $(origin).val(ui.item.label);

                                    validate_form();

                                    // Get the Destinations
                                    $.ajax({
                                        url: api_address + "get_destination_stations/" + ui.item.value,
                                        method: "GET",
                                        headers: {
                                            "Authorization": token
                                        }
                                    })
                                        .done(function (response) {
                                            // console.log( ui.item );
                                            $("#pb_sf_f_destino").attr("disabled", false);
                                            $(destination).attr("readonly", false);
                                            $(destination).attr("placeholder", "Seleccione Destino");

                                            // console.log( response );
                                            destinations = parseResults(response.content);

                                            var fuseDestinations = new Fuse(destinations, fuseOptions);

                                            $(destination)
                                                .blur(function () {
                                                    var keyEvent = $.Event("keydown");
                                                    keyEvent.keyCode = $.ui.keyCode.ENTER;
                                                    $(this).trigger(keyEvent);
                                                    // Stop event propagation if needed
                                                    return false;
                                                })
                                                .autocomplete({
                                                    open: function () {
                                                        $(this)
                                                            .autocomplete('widget')
                                                            .addClass('pb_autocomplete')
                                                            .wrap('<div class="pb_autocomplete_wrapper"></div>');
                                                    },
                                                    minLength: 0,
                                                    //source: destinations,
                                                    source: function (request, response) {
                                                        var citiesList = [];
                                                        var result = fuseDestinations.search(request.term);
                                                        result = result.sort((a, b) => a.score - b.score);
                                                        result.forEach(item => {
                                                            if (item.score < 0.5)
                                                                citiesList.push(item.item);
                                                            else if (item.score < 0.4)
                                                                citiesList.push(item.item);
                                                        })
                                                        result = citiesList;
                                                        response(result);
                                                    },
                                                    autoFocus: true,
                                                    focus: function (event, ui) {
                                                        //  validate_form();
                                                        //  destination_id = ui.item.value;

                                                        // $(this).val(ui.item.label);
                                                        return false;
                                                    },
                                                    select: function (event, ui) {

                                                        if (isMobile) {
                                                            $('#div_container_destino').parent().hide();
                                                            $('#pb_sf_f_destino').val(ui.item.label);
                                                        }


                                                        destination_id = ui.item.value;
                                                        $(destination).val(ui.item.label);
                                                        validate_form();
                                                        return false;
                                                    }

                                                })
                                                .autocomplete("instance")._renderItem = function (ul, item) {
                                                    return $("<li>")
                                                        .append("<a>" + item.label + "</a>")
                                                        .appendTo(ul);
                                                };
                                        });

                                    return false;
                                }
                            };

                            // Set Autocomplete Origin Field
                            $(origin)
                                .blur(function () {
                                    var keyEvent = $.Event("keydown");
                                    keyEvent.keyCode = $.ui.keyCode.ENTER;
                                    $(this).trigger(keyEvent);
                                    // Stop event propagation if needed
                                    return false;
                                })
                                .autocomplete(options)
                                .autocomplete("instance")._renderItem = function (ul, item) {
                                    return jQuery("<li>")
                                        .append("<a>" + item.label + "</a>")
                                        .appendTo(ul);
                                };

                            $("#pb_sf_origin, #pb_sf_destiny").on('blur', function () {
                                validate_form();
                            })

                            // Load the components to the form
                            $('#div_container_ida', search_form).append(origin);
                            $('#div_container_destino', search_form).append(destination);

                            $('#div_container_salida', search_form).append(departure_date);
                            $('#div_container_retorno', search_form).append(return_date);

                            $('#button_container', search_form).append(button);

                            if (logoDesarrolladoPinbus) {
                                $('#pinbus-desarrollado_pinbus', search_form).append($(`<img src="${desarrollado_pinbus}" alt="Desarrollado por Pinbus" height="30" />`));
                            }



                            $("#pinbus-search-form").html(search_form).on("submit", function (e) {
                                e.preventDefault();

                                if (origin_id != '' && destination_id != '' && $("#pb_sf_departure_submit").val() != '' && token != '') {

                                    var params = {
                                        origen: origin_id,
                                        destino: destination_id,
                                        pasajeros: $('#pb_sf_pasajeros').val(),
                                    };

                                    params.salida = $("#pb_sf_departure_submit").val();

                                    if ($("#pb_sf_return_submit").val())
                                        params.retorno = $("#pb_sf_return_submit").val();


                                    let url_params = `${params.origen}/${params.destino}?salida=${params.salida}`;

                                    if (params.retorno)
                                        url_params = `${url_params}&retorno=${params.retorno}`;

                                    window.open(`${site_url}/buscar/${url_params}`);


                                }

                            });

                            if (po == 'horizontal') {
                                $("#pinbus-search-form").css('max-width', '100%', 'important');
                            }

                            $('#pb_sf_f_origin').on('focus', function () {
                                // console.log('aaaaa');
                                $('#div_container_ida').parent().show();
                                $('#div_container_ida').find('input').focus();
                            });


                            $('#pb_sf_f_destino').on('focus', function () {
                                // console.log('aaaaa');
                                $('#div_container_destino').parent().show();
                                $('#div_container_destino').find('input').focus();
                            });


                        });
                });

            $.datepicker.regional['es'] = {
                closeText: 'Cerrar',
                prevText: '<Ant',
                nextText: 'Sig>',
                currentText: 'Hoy',
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
                dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
                weekHeader: 'Sm',
                dateFormat: 'D d, M',
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: ''
            };

            $.datepicker.setDefaults($.datepicker.regional['es']);


        });



        $(document).on('focus', "#pb_sf_search_form input.required", function () {
            validate_form()
        });


        function validate_form() {
            var toValidate = $("#pb_sf_search_form input.required");
            toValidate.each(function () {

                $("#pb_sf_button_submit").prop("disabled", function () {

                    var valid = false;

                    $("#pb_sf_search_form input.required").each(function () {
                        if (valid) {
                            return valid;
                        }
                        var input = $.trim($(this).val());
                        valid = !input;
                    });
                    // console.log( 'Validando Form' );
                    return valid;
                });
            });
        }

    }



    function parseResults(data) {
        var results = [];

        for (var key in data) {

            if (typeof data[key]['city'] !== "undefined") {
                var city = {
                    value: data[key]['city'].id,
                    label: data[key]['city'].name + ', ' + data[key]['city'].code,
                };
                results.push(city);
            }

        };
        return results
    };
}(window, document));
